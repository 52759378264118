<template>
  <div>
    <div class="py-4 flex flex-row justify-end" v-if="!showForm"><button type="button" class="button-submit px-10" @click="showForm = !showForm">Add New Blog</button></div>
    <div class="flex w-full py-4 border-t-2 border-gray-400" v-if="!showForm">
      <table class="table-auto w-full text-center justify-items-center">
        <thead>
          <tr>
            <th>Title</th>
            <th>Image</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, key) in list" :key="key" class="justify-items-center" :class="{'bg-gray-200': key%2 == 0, 'bg-white': key%2 == 1}">
            <td>{{row.title}}</td>
            <td align="center"><img :src="row.image" :alt="row.title" width="100" srcset=""></td>
            <td>{{row.status == "1" ? "Active" : "Non Active"}}</td>
            <td>
              <button type="button" @click="edit(row)" class="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-2.5 py-1.5 mr-2 mb-2 dark:focus:ring-yellow-900"><font-awesome-icon icon="fa-solid fa-pen-to-square" /></button>
              <button type="button" @click="deleteData(row)" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-2.5 py-1.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"><font-awesome-icon icon="fa-solid fa-trash" /></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <div class="grid gap-8 w-full pt-4">
          <ValidationObserver v-slot="{ invalid }">
          <div class="flex flex-col gap-4">
              <div class="w-full">
                  <label for="title" class="form-label block mb-2 text-base font-bold">Title</label>
                  <ValidationProvider class="block" name="title" :rules="{ required: true }">
                      <input type="text" id="title" v-model="form.title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="Title">
                  </ValidationProvider>
              </div>
              <div class="w-full">
                  <ValidationProvider class="block" name="image" :rules="{ required: true }">
                      <div class="flex">
                        <div class="mb-3 w-96">
                          <label for="title" class="form-label block mb-2 text-base font-bold">Image Banner</label>
                          <input ref="imageBanner" class="form-control
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="file" id="formFile">
                        </div>
                      </div>
                  </ValidationProvider>
              </div>
              <div class="w-full">
                  <label for="rider_email" class="block mb-2 text-base font-bold">Content</label>
                  <ValidationProvider class="block" name="email" :rules="{ required: true }">
                      <ckeditor :editor="editor" v-model="form.content" :config="editorConfig"></ckeditor>
                  </ValidationProvider>
              </div>
              <div class="w-full flex flex-row justify-between">
                <button class="button-cancel text-lg px-10" type="button" @click="cancel">Cancel</button>
                <button :disabled="invalid" class="button-submit text-lg px-10" type="button" @click="save">Submit</button>
              </div>
          </div>
          </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: "BlogArticle",

  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
          toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo' ],
          table: {
              toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
          },
          extraPlugins: [this.uploader],
          language: 'en',
      },
      list: [],
      showForm: false,
      isEdit: false,
      form: {
        title: "",
        type: "1",
        content: "",
        id: "",
      },
      formDefault: {
        title: "",
        type: "1",
        content: "",
        id: "",
      }
    };
  },

  created() {
    this.$store.commit("setBreadcrumbItems", [
      { text: "Home", routeName: "Home" },
      { text: "Account", routeName: "AccountProfile" },
      { text: "Article", routeName: "Article" },
      { text: "Blog", routeName: "BlogArticle" },
    ]);

    this.getBlogData();
  },
  methods: {
    cancel(){
      this.showForm = false;
    },
    uploader(editor)
    {
        editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
            return new UploadAdapter( loader );
        };
    },
    async getBlogData() {
      this.$store.commit("setOverlayLoading", true);

      try {
        const response = await this.$http.get(
          `${this.$apiTripweWeb}/blog`,
          {
            params: {
              type: "1",
              date_start: "",
              date_end: "",
              search: "",
            }
          }
        );

        if (response.status == "200") {
          this.list = response.data.data;
        }
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    edit(selected) {
      this.form.id = selected.id;
      this.form.title = selected.title;
      this.form.content = selected.content;
      this.form.content = selected.content;
      this.showForm = true;
      this.isEdit = true;
    },
    async deleteData(data) {
      this.$store.commit("setOverlayLoading", true);
      try {
        const response = await this.$http.delete(
          `${this.$apiTripweWeb}/blog/${data.id}`
        ).catch(function (error) {
            if (error.response) {
              if(error.response.status == "422"){
                throw error.response.data.message;
              }
              if(error.response.status == "400"){
                throw error.response.data.message;
              }
            }
          });
        if(response.status == "400"){
          throw response.data.message;
        }
        if(response.status == "422"){
          throw response.data.message;
        }
        if(response.status == "200"){
          this.$toasted.global.success(response.data.message);
          this.getBlogData();
        }
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async save() {
      this.$store.commit("setOverlayLoading", true);
      try {
        let data = new FormData();
        data.append('title', this.form.title);
        if(this.$refs.imageBanner.files[0] != null){
          data.append('image', this.$refs.imageBanner.files[0]); 
        }
        data.append('content', this.form.content);
        data.append('type', this.form.type);
        let response;
        if(!this.isEdit){
          response = await this.$http.post(
            `${this.$apiTripweWeb}/blog`,data, {
              headers: { 
                  'Content-Type': 'multipart/form-data',
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Credentials': true,
                  'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
              },
          },
          );
        }else{
          data.append("_method", "put")
          response = await this.$http.post(
            `${this.$apiTripweWeb}/blog/${this.form.id}`,data
          ).catch(function (error) {
            if (error.response) {
              if(error.response.status == "422"){
                throw error.response.data.message;
              }
              if(error.response.status == "400"){
                throw error.response.data.message;
              }
            }
          });
        }

        if(response.status == "200" && response.data.status == "200"){
          this.$toasted.global.success(response.data.message);
          this.showForm = false;
          this.isEdit = false;
          this.clearForm();
          this.getBlogData();
        }
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    clearForm(){
      this.form = Object.assign({}, this.formDefault);
    }
  },

};
</script>
